<template>
  <div class="bg-fff" style="height: 100%;">
    <div class="login-nei-rong">
      <div class="login-body">
        <nav-header fixed page-name="Index" title="登入">
          <template #left>
            <router-link :to="{name:'Index'}">首页</router-link>
          </template>
        </nav-header>
        <user-login-top/>
        <div class="body-margin an-niu">
          <van-button class="an-niu-item" @click="wxLogin" type="primary" block round>微信一键登入</van-button>
          <van-button block round :to="{name:'MobileLogin'}">手机登入/注册</van-button>
        </div>
      </div>
      <div class="login-xie-yi">
        <xie-yi v-model:val="form.xie_yi"/>
      </div>
    </div>
  </div>
</template>

<script>


import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'UserLogin',
  components: {NavHeader},
}
</script>
<script setup>
import UserLoginTop from "./public/UserLoginTop";
import XieYi from "./public/XieYi";
import {ref} from 'vue'
import {TongYiXieYi} from "@/api/AlertMessage";

let form = ref({})

function wxLogin() {
  if (!form.value.xie_yi) {
    TongYiXieYi().then(() => {
      form.value.xie_yi = true
    })
  } else {
    denRu()
  }

}

function denRu() {
  location.href = '/gzhphp/login/wxLogin'
}
</script>

<style scoped lang="less">
@import "../../assets/login";

.an-niu {
  margin-top: 92px;

  .an-niu-item {
    margin-bottom: 45px;
  }
}


</style>
